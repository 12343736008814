// ContactListContext.js

import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getContactListSubmissionsByUserId, saveContactListSubmission } from '../utils/dbUtilities';

export const ContactListContext = createContext();

export const ContactListProvider = ({ children }) => {
  const [contactList, setContactList] = useState(null);
  const { currentUser } = useAuth();

  const fetchContactList = useCallback(async (userId) => {
    if (!userId) {
      setContactList({});
      return;
    }
    try {
      const submissions = await getContactListSubmissionsByUserId(userId);
      setContactList(submissions);
    } catch (error) {
      console.error("Error fetching contact list:", error);
      setContactList({});
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchContactList(currentUser.uid);
    } else {
      setContactList({});
    }
  }, [currentUser, fetchContactList]);

  const addContactSubmission = async (submissionData) => {
    try {
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const newSubmission = {
        ...submissionData,
        userId: currentUser.uid,
        timestamp: submissionData.timestamp || new Date().toISOString(),
      };
      const savedSubmission = await saveContactListSubmission(newSubmission);
      setContactList((prevList) => {
        const updatedList = {...prevList};
        if (!updatedList[savedSubmission.postId]) {
          updatedList[savedSubmission.postId] = [];
        }
        updatedList[savedSubmission.postId].push(savedSubmission);
        return updatedList;
      });
    } catch (error) {
      console.error('Error adding contact submission:', error);
      throw error;
    }
  };

  return (
    <ContactListContext.Provider value={{ contactList, addContactSubmission }}>
      {children}
    </ContactListContext.Provider>
  );
};

export default ContactListContext;
