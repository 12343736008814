// HomePage.js

import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { useAuth } from '../contexts/AuthContext';
import './HomePage.css';
import { Box, Paper, List, ListItem, Typography, Fade, IconButton, useMediaQuery, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IoTrendingUpOutline } from 'react-icons/io5';
import VisibilityTracker from './VisibilityTracker';
import { getTotalDiscoveryBoxImpressions } from '../utils/dbUtilities';
import { FixedSizeList as VirtualizedList } from 'react-window';

const HomePage = () => {
  const { posts, fetchMorePosts, loading, hasMore } = useContext(ClipsPostsContext);
  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  const [clickedUserNames, setClickedUserNames] = useState(() => {
    const saved = localStorage.getItem('clickedUserNames');
    return saved ? JSON.parse(saved) : {};
  });

  const [randomPostsKey, setRandomPostsKey] = useState(0);
  const [randomBiosKey, setRandomBiosKey] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [userBios, setUserBios] = useState({});
  const [totalImpressions, setTotalImpressions] = useState(0);

  const isMobile = useMediaQuery('(max-width:768px)');

  const postContainerStyle = useMemo(() => ({
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto 15px',
    padding: '0 15px',
    boxSizing: 'border-box'
  }), []);

  // Scroll to top on user change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authUser]);

  // Fetch total impressions periodically
  const fetchTotalImpressions = useCallback(async () => {
    try {
      const impressions = await getTotalDiscoveryBoxImpressions();
      setTotalImpressions(prevCount => (impressions > prevCount ? impressions : prevCount));
    } catch (error) {
      console.error('Error fetching total impressions:', error);
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setTimeout(fetchTotalImpressions, 500);
      }
    };

    const handleFocus = () => {
      setTimeout(fetchTotalImpressions, 500);
    };

    // Initial fetch
    setTimeout(fetchTotalImpressions, 500);

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [fetchTotalImpressions, location.key]);

  // Sync with localStorage
  useEffect(() => {
    const handleStorage = () => {
      const savedPosts = localStorage.getItem('clickedPosts');
      const savedUserNames = localStorage.getItem('clickedUserNames');
      if (savedPosts) setClickedPosts(JSON.parse(savedPosts));
      if (savedUserNames) setClickedUserNames(JSON.parse(savedUserNames));
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  const aggregateRecentUserBios = useCallback((allPosts) => {
    const bios = {};
    allPosts
      .filter(post =>
        post.author &&
        post.bio && 
        post.bio.trim() !== '' && 
        !post.deleted &&
        !post.isReported
      )
      .sort((a, b) => {
        const timestampA = b.timestamp?.toDate ? b.timestamp.toDate() : new Date(b.timestamp);
        const timestampB = a.timestamp?.toDate ? a.timestamp.toDate() : new Date(a.timestamp);
        return timestampA - timestampB;
      })
      .forEach((post) => {
        if (!bios[post.author]) {
          bios[post.author] = {
            bio: post.bio,
            city: post.city || '',
            state: post.state || '',
            name: post.userName || '',
          };
        }
      });
    return bios;
  }, []);

  useEffect(() => {
    if (posts && posts.length > 0) {
      setUserBios(aggregateRecentUserBios(posts));
    }
  }, [posts, aggregateRecentUserBios]);

  const handlePostClick = useCallback((postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
}, [clickedPosts, navigate]);

const handleRefresh = useCallback(() => {
    setIsInitialLoad(false);
    setRandomPostsKey(prevKey => prevKey + 1);
}, []);

const handleBioRefresh = useCallback(() => {
    setRandomBiosKey(prevKey => prevKey + 1);
}, []);

const truncateBio = useCallback((bio) => {
    const words = bio.split(' ');
    return words.length > 8 ? words.slice(0, 8).join(' ') + '...' : bio;
}, []);

const getLocationString = useCallback((city, state) => {
    if (city && state) return `(${city}, ${state})`;
    if (city) return `(${city})`;
    if (state) return `(${state})`;
    return '';
}, []);

const handleUserNameClick = useCallback((userName) => {
    const updatedClickedUserNames = { ...clickedUserNames, [userName]: true };
    setClickedUserNames(updatedClickedUserNames);
    localStorage.setItem('clickedUserNames', JSON.stringify(updatedClickedUserNames));
    navigate(`/clipsusersposts/${userName}`);
}, [clickedUserNames, navigate]);

// Compute uniquePosts and discoverPosts
const uniquePosts = useMemo(() => {
    if (!posts) return [];
    return Array.from(new Map(posts.map(post => [post.id, post])).values());
}, [posts]);

const discoverPosts = useMemo(() => {
    return uniquePosts.filter(post => post.showInDiscoverBox);
}, [uniquePosts]);

const randomDiscoverPosts = useMemo(() => {
    if (!discoverPosts.length) return [];
    if (isInitialLoad && !isMobile) {
        // Initial set: only "HyroVault" or "HyroFits" posts
        return discoverPosts.filter(
            post => (post.title === "HyroVault" || post.title === "HyroFits") && !post.deleted && !post.isReported
        );
    } else if (isInitialLoad && isMobile) {
        // On mobile, return empty initially
        return [];
    } else {
        // After initial load or refresh, randomize
        const shuffledPosts = [...discoverPosts]
            .filter(post => !post.deleted && !post.isReported)
            .sort(() => Math.random() - 0.5);
        return shuffledPosts.slice(0, Math.min(5, shuffledPosts.length));
    }
}, [discoverPosts, randomPostsKey, isInitialLoad, isMobile]);

const randomUserBios = useMemo(() => {
    const entries = Object.entries(userBios).filter(
        ([author]) => !posts.find((post) => post.author === author && post.isReported)
    );
    for (let i = entries.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [entries[i], entries[j]] = [entries[j], entries[i]];
    }
    return entries.slice(0, Math.min(8, entries.length));
}, [userBios, randomBiosKey, posts]);

// If posts are not yet loaded, show a quick loading indicator
// (If needed, add a postsLoading state in ClipsPostsContext)
if (!posts || posts.length === 0) {
    return (
        <Box sx={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
}

return (
    <Box sx={{ pb: 4 }}>
        <div className="posts">
            {discoverPosts.length > 0 && (
                <Fade in={true} timeout={800} unmountOnExit>
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 4,
                            mt: 4,
                            width: '100%',
                            maxWidth: '800px',
                            margin: '0 auto',
                            mb: 2,
                            borderRadius: '16px',
                            backgroundColor: '#fff',
                            overflow: 'hidden',
                        }}
                    >
                        <Box sx={{ width: '100%', mb: 2, textAlign: 'left' }}>
                            {!isMobile ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f', lineHeight: 1.2 }}>
                                        Discover Posts
                                    </Typography>
                                    <IconButton 
                                        onClick={handleRefresh}
                                        sx={{ 
                                            color: '#007aff',
                                            padding: '8px',
                                            backgroundColor: '#f5f5f7',
                                            '&:hover': { backgroundColor: '#e8e8ed' },
                                            transition: 'background-color 0.3s ease',
                                        }}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f', lineHeight: 1.2, mb: 1 }}>
                                        Discover Posts
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: '400', color: '#86868b', mb: 2, textAlign: 'center' }}>
                                        Explore Projects, Ventures, Causes & Ideas
                                    </Typography>
                                    <IconButton 
                                        onClick={handleRefresh}
                                        sx={{ 
                                            color: '#007aff',
                                            padding: '16px',
                                            backgroundColor: '#f5f5f7',
                                            '&:hover': { backgroundColor: '#e8e8ed' },
                                            transition: 'background-color 0.3s ease',
                                        }}
                                    >
                                        <RefreshIcon sx={{ fontSize: '48px' }} />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>

                        <List sx={{ width: '100%', padding: 0 }}>
                        {randomDiscoverPosts.map((post, index) => (
    <VisibilityTracker key={post.id} postId={post.id} isDiscoveryBox={true}>
        <Fade in={true} timeout={500 + index * 100} unmountOnExit>
            <ListItem 
                component="div"
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start',
                    padding: '16px 0',
                    borderRadius: '8px',
                    '&:hover': { backgroundColor: 'transparent' },
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                    <Typography
                        variant="body1"
                        component="span"
                        onClick={() => handlePostClick(post.id)}
                        sx={{
                            color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            '&:hover': { textDecoration: 'underline' },
                            fontSize: '1.1rem',
                            marginBottom: '4px',
                        }}
                    >
                        {post.title}
                    </Typography>
                    <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
                        {post.city && post.state ? `(${post.city}, ${post.state})` : 
                            post.city ? `(${post.city})` : 
                            post.state ? `(${post.state})` : ''}
                    </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}>
                    {post.summary || (post.description?.length > 100 ? `${post.description.substring(0, 100)}...` : post.description)}
                </Typography>
            </ListItem>
        </Fade>
    </VisibilityTracker>
))}
</List>

<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: 4, flexDirection: 'column' }}>
    <Typography variant="body1" sx={{ color: '#333', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <span style={{ 
            backgroundColor: '#fff', 
            color: '#000', 
            fontFamily: 'Bebas Neue, sans-serif', 
            fontSize: '1.5rem', 
            padding: '5px 10px', 
            border: '2px solid #000', 
            borderRadius: '8px', 
            marginRight: '10px' 
        }}>
            {totalImpressions.toLocaleString()}
        </span>
        Total Post Views
    </Typography>

    <Box 
        sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            width: 'auto',
            mt: 2,
            cursor: 'pointer',
            backgroundColor: '#f5f5f7',
            padding: '8px 16px',
            borderRadius: '8px',
            transition: 'background-color 0.3s ease',
            '&:hover': {
                backgroundColor: '#e8e8ed',
            },
            borderWidth: '2px',
            borderColor: '#007aff',
            borderStyle: 'solid'
        }}
        onClick={() => navigate('/trending')}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: '#1a0dab',
            }}
        >
            <IoTrendingUpOutline size={24} />
            <Typography 
                variant="body1" 
                sx={{ 
                    fontWeight: 500,
                    color: '#1a0dab',
                }}
            >
                View Trending Posts
            </Typography>
        </Box>
    </Box>
</Box>
</Paper>
</Fade>
)}

{uniquePosts.filter(post => post.showOnHomePage).map(post => (
<div key={post.id} className="post-container" style={postContainerStyle}>
    <ClipsPost {...post} />
</div>
))}

<Fade in={true} timeout={800} unmountOnExit>
<Paper
    elevation={3}
    sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        mt: 4,
        mb: 4,
        width: '100%',
        maxWidth: '800px', 
        margin: '0 auto 2rem auto',
        borderRadius: '16px',
        backgroundColor: '#fff',
        overflow: 'hidden',
    }}
>
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            mb: 4,
        }}
    >
        <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
            Discover People
        </Typography>
        <IconButton 
            onClick={handleBioRefresh}
            sx={{ 
                color: '#007aff',
                backgroundColor: '#f5f5f7',
                '&:hover': { backgroundColor: '#e8e8ed' },
                transition: 'background-color 0.3s ease',
            }}
        >
            <RefreshIcon />
        </IconButton>
    </Box>

    {Object.keys(userBios).length === 0 ? (
        <Typography variant="body1" sx={{ color: '#86868b' }}>
            No user profiles available.
        </Typography>
    ) : (
        <List sx={{ width: '100%', padding: 0 }}>
            {randomUserBios.map(([author, { bio, city, state, name }], index) => (
                <Fade in={true} timeout={500 + index * 100} key={`${author}-${index}`} unmountOnExit>
                    <ListItem
                        component="div"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '16px 0',
                            borderRadius: '8px',
                            '&:hover': { backgroundColor: 'transparent' },
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                            <Typography
                                variant="body1"
                                component="span"
                                onClick={() => handleUserNameClick(author)}
                                sx={{
                                    color: clickedUserNames[author] ? '#551A8B' : '#1a0dab',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    '&:hover': { textDecoration: 'underline' },
                                    fontSize: '1.1rem',
                                    marginBottom: '4px',
                                }}
                            >
                                {name || author}
                            </Typography>
                            <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
                                {getLocationString(city, state)}
                            </Typography>
                        </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}
                      >
                        {truncateBio(bio)}
                      </Typography>
                    </ListItem>
                  </Fade>
                ))}
              </List>
            )}
          </Paper>
        </Fade>
      </div>
    </Box>
  );
};

export default HomePage;


