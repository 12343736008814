// ClipsPostPage.js

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Card, CardActions, CardContent, CardMedia, Typography, IconButton, Tooltip, useMediaQuery, Fade, Paper, List, ListItem } from '@mui/material';
import { BsMegaphone } from 'react-icons/bs';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FaRegPaperPlane, FaLink, FaInstagram, FaTwitter, FaTiktok, FaYoutube, FaFacebook } from 'react-icons/fa';
import { GoAlert } from "react-icons/go";
import { CiViewList } from "react-icons/ci";
import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { deletePostFromDB } from '../utils/dbUtilities';
import { timeAgo } from '../utils/timeFormatter';
import ClipsReportForm from './ClipsReportForm';
import ChatWindow from './ChatWindow';
import ContactList from './ContactList';
import SharePost from './SharePost';
import CloseButton from './CloseButton';
import './Post.css';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, getDocs, doc } from 'firebase/firestore';
import Feedback from './Feedback';
import { FeedbackProvider } from '../contexts/FeedbackContext';
import VisibilityTracker from './VisibilityTracker';
import { 
    incrementViewCount, 
    incrementLinkClickCount,
    incrementInstagramClickCount,
    incrementTwitterClickCount,
    incrementTiktokClickCount,
    incrementYoutubeClickCount,
    incrementFacebookClickCount 
} from '../utils/dbUtilities';

// Add shortenUrl function here
const shortenUrl = (url) => {
    const maxLength = 25;  // Define the max length for display
    return url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
};

const ClipsPostPage = () => {
    const { posts, likePostById, unlikePostById, updatePostLikeStatus, deletePostById } = useContext(ClipsPostsContext);
    const { reportPost } = useContext(ClipsReportsContext);
    const [showReportForm, setShowReportForm] = useState(false);
    const [showContactList, setShowContactList] = useState(false);
    const [showSharePost, setShowSharePost] = useState(false);
    const [showChatWindow, setShowChatWindow] = useState(false);
    const { currentUser, isLoggedIn, isAdmin } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [isProfileImageExpanded, setIsProfileImageExpanded] = useState(false);
    const [randomPostsKey, setRandomPostsKey] = useState(0);
    const [clickedPosts, setClickedPosts] = useState(() => {
        const saved = localStorage.getItem('clickedPosts');
        return saved ? JSON.parse(saved) : {};
    });

    const canSeeLikesCount = () => {
        console.log('Checking likes count access:', {
            isAuthor: isAuthor,
            isAdmin: isAdmin,
            currentUser: currentUser
        });
        return isAuthor || isAdmin;
    };       

    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');
    const isLargeScreen = useMediaQuery('(min-width: 1024px) and (max-width: 1366px)');
    const isSurfacePro7 = useMediaQuery('(min-width: 1368px) and (max-width: 1368px)');
    const isVeryLargeScreen = useMediaQuery('(min-width: 1300px)');

// Effect for handling storage changes
useEffect(() => {
    const handleStorage = () => {
        const saved = localStorage.getItem('clickedPosts');
        if (saved) {
            setClickedPosts(JSON.parse(saved));
        }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
}, []);

// Effect for initial post fetch and scroll to top
useEffect(() => {
    window.scrollTo(0, 0);

    if (!id) {
        setIsLoading(false);
        return;
    }

    const fetchedPost = posts.find(p => p.id && p.id.toString() === id);
    setPost(fetchedPost);
    setIsLoading(false);
}, [posts, navigate, id]);

// New useEffect hook to check if post is deleted or doesn't exist
useEffect(() => {
    if (!isLoading && post) {
        if (post.deleted) {
            setIsDeleted(true);
        }
    } else if (!isLoading && !post) {
        setIsDeleted(true);
    }
}, [isLoading, post]);

// Effect for view count tracking
useEffect(() => {
    if (post?.id) {
        incrementViewCount(post.id);
    }
}, [post?.id]);

// Effect for likes tracking
useEffect(() => {
    if (post && post.id) {
        const likesQuery = query(collection(db, 'clipsLikes'), where('postId', '==', post.id));
        const unsubscribe = onSnapshot(likesQuery, (snapshot) => {
            const likes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLikeCount(likes.length);
            if (currentUser) {
                const isLiked = likes.some(like => like.userId === currentUser.uid);
                setPost(prevPost => ({ ...prevPost, liked: isLiked }));
            }
        });

        return () => unsubscribe();
    }
}, [post, currentUser]);

// New effect for real-time analytics updates
useEffect(() => {
    if (post?.id) {
        const postRef = doc(db, 'clipsPosts', post.id);
        const unsubscribe = onSnapshot(postRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setPost(prevPost => ({
                    ...prevPost,
                    viewCount: data.viewCount || 0,
                    linkClickCount: data.linkClickCount || 0,
                    impressionCount: data.impressionCount || 0,
                    instagramClickCount: data.instagramClickCount || 0,
                    twitterClickCount: data.twitterClickCount || 0,
                    tiktokClickCount: data.tiktokClickCount || 0,
                    youtubeClickCount: data.youtubeClickCount || 0,
                    facebookClickCount: data.facebookClickCount || 0
                }));
            }
        });

        return () => unsubscribe();
    }
}, [post?.id]);

// Random posts memo
const randomPosts = useMemo(() => {
    const validPosts = posts.filter(p => 
      p.id !== id && 
      !p.deleted &&
      !p.isReported
    );
    const shuffledPosts = [...validPosts].sort(() => Math.random() - 0.5);
    return shuffledPosts.slice(0, 3);
  }, [posts, id, randomPostsKey]);

// Updated click handlers for social media and links
const handleLinkClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
        await incrementLinkClickCount(post.id);
    }
    openLinkInNewTab(post.link.includes('http') ? post.link : `https://${post.link}`);
};

const handleInstagramClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
        await incrementInstagramClickCount(post.id);
    }
    openLinkInNewTab(`https://instagram.com/${post.instagram.replace('@', '')}`);
};

const handleTwitterClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
        await incrementTwitterClickCount(post.id);
    }
    openLinkInNewTab(`https://twitter.com/${post.twitter.replace('@', '')}`);
};

const handleTiktokClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
        await incrementTiktokClickCount(post.id);
    }
    openLinkInNewTab(`https://tiktok.com/@${post.tiktok.replace('@', '')}`);
};

const handleYoutubeClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
        await incrementYoutubeClickCount(post.id);
    }
    openLinkInNewTab(`https://youtube.com/@${post.youtube.replace('@', '')}`);
};

const handleFacebookClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
        await incrementFacebookClickCount(post.id);
    }
    openLinkInNewTab(`https://www.facebook.com/${post.facebook}`);
};

const handlePostClick = (postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
};

const handleRefresh = () => {
    setRandomPostsKey(prevKey => prevKey + 1);
};

const handleLike = async () => {
    if (!isLoggedIn || !currentUser) {
        navigate('/login');
        return;
    }
    if (post.liked) {
        const likeQuery = query(collection(db, 'clipsLikes'), where('postId', '==', post.id), where('userId', '==', currentUser.uid));
        const snapshot = await getDocs(likeQuery);
        const likeDoc = snapshot.docs[0];
        if (likeDoc) {
            await deleteDoc(likeDoc.ref);
        }
    } else {
        await addDoc(collection(db, 'clipsLikes'), {
            postId: post.id,
            userId: currentUser.uid,
            timestamp: new Date()
        });
    }
};

const handleRemove = async () => {
    if (window.confirm("Are you sure you want to delete this post?")) {
        try {
            await deletePostFromDB('clipsPosts', id);
            setIsDeleted(true);
            setPost(null);
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    }
};

const handleUsernameClick = () => {
    navigate(`/clipsusersposts/${post.author}`);
};

const toggleReportForm = () => {
    if (!isLoggedIn) {
        navigate('/login');
        return;
    }
    setShowReportForm(!showReportForm);
};

const toggleContactList = () => {
    if (!isLoggedIn) {
        navigate('/login');
        return;
    }
    setShowContactList(!showContactList);
};

const closeContactList = () => {
    setShowContactList(false);
};

const openChatWindow = () => {
    if (!isLoggedIn) {
        navigate('/login');
        return;
    }
    setShowChatWindow(true);
};

const navigateToCategoryPosts = (category) => {
    navigate(`/categoryposts/${category}`);
};

const navigateToCityPosts = (city) => {
    navigate(`/cityposts/${city}`);
};

const navigateToStatePosts = (state) => {
    navigate(`/stateposts/${state}`);
};

const navigateToCountryPosts = (country) => {
    navigate(`/countryposts/${country}`);
};

const navigateToLocationPosts = (location) => {
    navigate(`/locationposts/${location}`);
};

const openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const handleProfileImageClick = () => {
    setIsProfileImageExpanded(!isProfileImageExpanded);
};

const splitText = (text) => {
    if (!text) return [];
    return text.split(/(?<!\b(?:Dr|Mr|Mrs|Miss|Ms|Prof|Rev|Hon|St|Ave|Blvd|Rd|Ln|Jr|Sr|Inc|Ltd|Co|Corp|Gov|Sen|Rep|Col|Gen|Capt|Lt|Maj|Adm|Cpl|Sgt|Pvt|Pres|VP|Dir|Mgr|Asst|Supt|Treas|Sec|Chmn|Cmdr|Dep|Adm|Supt|Eng|Arch|Dept|Div|Esq)\.)(?<!\b[A-Z][a-z]*\.)(?<=\.|\?|!)\s+(?=[A-Z])/g);
};

if (isLoading) {
    return null;
}

if (isDeleted || !post) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                pt: {
                    xs: 10,
                    sm: 12,
                    md: 15,
                    lg: 15,
                    xl: 15,
                },
                ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
                mt: isVeryLargeScreen ? -2 : 
                    isSurfacePro7 ? 10 : 
                    isMediumScreen ? '5px' : 
                    { xs: 2, sm: 10 },
                '@media (min-width: 1030px) and (max-width: 1300px)': {
                    mt: -5,
                },
            }}
            className="post-page-container"
        >
            <Card
                className="post clips-post-page"
                sx={{
                    maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                    mb: 2,
                    marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                    marginRight: isSurfacePro7 ? 'auto' : '0',
                }}
            >
                <CardContent>
                    <Typography variant="h6">This Post No Longer Exists</Typography>
                </CardContent>
            </Card>
        </Box>
    );
}

if (!post) {
    return null;
}

if (post.isReported) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                pt: {
                    xs: 10,
                    sm: 12,
                    md: 15,
                    lg: 15,
                    xl: 15,
                },
                ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
                mt: isVeryLargeScreen ? -2 : 
                    isSurfacePro7 ? 10 : 
                    isMediumScreen ? '5px' : 
                    { xs: 2, sm: 10 },
            }}
            className="post-page-container"
        >
            <Card
                className="post"
                sx={{
                    maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                    mb: 2,
                    marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                    marginRight: isSurfacePro7 ? 'auto' : '0',
                    '@media (min-width: 1264px) and (max-width: 1264px)': {
                        mt: -3,
                    },
                    '@media (min-width: 1281px) and (max-width: 1281px)': {
                        mt: -3,
                    },
                }}
            >
                <CardContent>
                    <Typography variant="h6">Reported Post Under Review</Typography>
                </CardContent>
            </Card>
        </Box>
    );
}

const locationString = `${post.city}${post.state ? `, ${post.state}` : ''}${post.country ? `${post.state ? ', ' : ''}${post.country}` : ''}`;
const postDate = post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp);
const lastEditedDate = post.lastEdited?.toDate ? post.lastEdited.toDate() : post.lastEdited;
const isAuthor = currentUser && currentUser.displayName === post?.author;

return (
    <FeedbackProvider>
        <VisibilityTracker 
            postId={id} 
            isDiscoveryBox={false} 
            isPostAuthor={post.author === currentUser?.uid}
            onImpressionUpdate={(count) => {
                setPost(prev => ({...prev, viewerImpressions: count}));
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                pt: {
                    xs: 10,
                    sm: 12,
                    md: 15,
                    lg: 15,
                    xl: 15,
                },
                ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
                mt: isVeryLargeScreen ? -2 : 
                    isSurfacePro7 ? 10 : 
                    isMediumScreen ? '5px' : 
                    { xs: 2, sm: 10 },
                '@media (min-width: 1264px) and (max-width: 1264px)': {
                    mt: -5,
                },
                '@media (min-width: 1281px) and (max-width: 1281px)': {
                    mt: -5,
                },
            }}
            className="post-page-container"
        >
                <Card
                    className="post clips-post-page"
                    sx={{
                        maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                        mb: 2,
                        marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                        marginRight: isSurfacePro7 ? 'auto' : '0',
                        paddingBottom: '40px', // Add this line for extra bottom spacing
                        '@media (min-width: 1264px) and (max-width: 1264px)': {
                            mt: -3,
                        },
                        '@media (min-width: 1281px) and (max-width: 1281px)': {
                            mt: -3,
                        },
                        '@media (min-width: 1030px) and (max-width: 1300px)': {
                            mt: '-20px',
                        },
                    }}
                >
                    {post.imageUrl && (
                        <CardMedia
                            component="img"
                            src={post.imageUrl}
                            alt={post.title}
                        />
                    )}
                    <CardContent
    sx={{
        width: '100%',
        alignItems: 'flex-start',
        position: 'relative',
        paddingTop: '40px', // Maintains top padding
    }}
>
    <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            boxSizing: 'border-box',
        }}
    >
        {/* Wrapper to move title and location text up */}
        <div style={{ marginTop: '-10px' }}> {/* Adjusts title position */}
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="clips-post-title"
                style={{
                    textAlign: 'left',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '5px', // Maintains spacing with location
                    width: '100%',
                }}
                onClick={() => handlePostClick(post.id)}
            >
                <span
                    className="title-link"
                    style={{
                        flex: '1 1 auto',
                        wordBreak: 'break-word',
                        color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                    }}
                >
                    {post.title}
                </span>
            </Typography>

            <Typography
                variant="body2"
                color="text.secondary"
                className="clips-post-location"
                style={{ 
                    textAlign: 'left', 
                    width: '100%', 
                    cursor: 'pointer',
                    marginBottom: '15px', // Increased spacing before description
                }}
            >
                <span onClick={(e) => { e.stopPropagation(); navigateToCityPosts(post.city); }} className="location-link">
                    {post.city}
                </span>
                {post.state && (
                    <>
                        ,{' '}
                        <span onClick={(e) => { e.stopPropagation(); navigateToStatePosts(post.state); }} className="location-link">
                            {post.state}
                        </span>
                    </>
                )}
                {post.country && (
                    <>
                        ,{' '}
                        <span onClick={(e) => { e.stopPropagation(); navigateToCountryPosts(post.country); }} className="location-link">
                            {post.country}
                        </span>
                    </>
                )}
                {' '}
                (<span onClick={(e) => { e.stopPropagation(); navigateToLocationPosts(post.location); }} className="location-link">
                    {post.location}
                </span>)
            </Typography>
        </div>

        {/* Description with adjusted spacing */}
        <Typography 
            variant="body1" 
            color="text.primary" 
            style={{ 
                textAlign: 'left', 
                width: '100%',
                margin: '10px 0 20px 0',  // Increased marginBottom for spacing before timestamp
                fontSize: '15px',          // Explicitly set font size
                lineHeight: 1.4,
                color: '#000000',
                fontFamily: '"Roboto", sans-serif',
                fontWeight: 400,           // Set consistent font weight
            }}
        >
            {splitText(post.description).length > 1 ? (
                <ul style={{ paddingLeft: '20px', marginTop: '5px' }}>
                    {splitText(post.description).map((sentence, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>{sentence.trim()}</li>
                    ))}
                </ul>
            ) : (
                post.description
            )}
        </Typography>

        {/* Timestamp Styling */}
        <Typography
            variant="body1"
            color="text.secondary"
            className="clips-post-author"
            style={{ 
                textAlign: 'left', 
                width: '100%', 
                cursor: 'pointer',
                fontFamily: '"Roboto", sans-serif',
                fontSize: '13px',
                marginBottom: lastEditedDate ? '5px' : '15px' // Add margin only if there's a last edited date
            }}
            onClick={handleUsernameClick}
        >
            Posted {timeAgo(postDate)} by <span className="username-link" style={{ fontWeight: 'bold', fontFamily: '"Roboto", sans-serif' }}>{post.author}</span>
        </Typography>

        {lastEditedDate && (
            <Typography
                variant="body2"
                color="text.secondary"
                className="last-edited"
                style={{
                    textAlign: 'left',
                    width: '100%',
                    cursor: 'pointer',
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '13px',
                    marginBottom: '15px' // Add margin to maintain spacing with next element
                }}
            >
                Last Edited {timeAgo(lastEditedDate)}
            </Typography>
        )}
    </div>
</CardContent>

        {/* Post Impressions Count for Post Viewers */}
        <Box 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                width: '100%',
                mt: 2,
                mb: 2
            }}
        >
            <Typography 
                variant="body1" 
                sx={{ 
                    color: '#000000', 
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '15px',
                    lineHeight: 1.4,
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: 700, // Changed from 400 to 700 (bold)
                }}
            >
                <span style={{ 
                    backgroundColor: '#fff', 
                    color: '#000', 
                    fontFamily: '"Roboto", sans-serif', 
                    fontSize: '15px', 
                    padding: '3px 8px', 
                    border: '2px solid #000', 
                    borderRadius: '6px', 
                    marginRight: '8px' 
                }}>
                    {post?.impressionCount?.toLocaleString() || 0}
                </span>
                Total Views
            </Typography>
        </Box>

        {/* URL Link - Add this section here */}
        {post.link && (
            <div style={{ 
                width: '100%',
                textAlign: 'center',
                marginTop: '15px',
                marginBottom: '10px'
            }}>
                <a 
                    href={post.link.includes('http') ? post.link : `https://${post.link}`}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleLinkClick(e);
                    }}
                    style={{ 
                        color: '#1a0dab',
                        textDecoration: 'none',
                        wordBreak: 'break-all',
                        display: 'inline-block',
                        fontSize: '16px',
                        fontWeight: 500
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {shortenUrl(post.link)}
                </a>
            </div>
        )}

{/* Actions Section */}
<CardActions disableSpacing style={{ justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={post.liked ? 'Unlike' : 'Like'}>
            <IconButton onClick={handleLike} sx={{ color: post.liked ? 'red' : 'inherit' }}>
                {post.liked ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
        </Tooltip>
        {/* Only show likes count if user is author or admin */}
        {canSeeLikesCount() && (
            <span className="votes-count">{likeCount}</span>
        )}
    </div>
    <div style={{ display: 'flex', gap: '10px' }}>
        {isAuthor ? (
            <>
                <Tooltip title="Edit">
                    <IconButton onClick={() => navigate(`/editclipspost/${id}`)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton onClick={() => {
                        if (window.confirm("Are you sure you want to delete this post?")) {
                            deletePostFromDB('clipsPosts', id);
                            setIsDeleted(true);
                        }
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
        ) : (
            <>
                <Tooltip title="Report">
                    <IconButton onClick={toggleReportForm}>
                        <OutlinedFlagIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Send Message">
                    <IconButton onClick={openChatWindow} style={{ color: 'inherit' }}>
                        <FaRegPaperPlane size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </IconButton>
                </Tooltip>
            </>
        )}
    </div>
</CardActions>

{/* Share and Contact Section */}
<div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%'
}}>

    {/* Conditional Heading for Social Handles */}
    {(post.instagram || post.twitter || post.tiktok || post.youtube || post.facebook) && (
        <Typography 
            variant="h6" 
            style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginBottom: '10px',
                color: '#000'
            }}
        >
            Follow the Growth
        </Typography>
    )}

    {/* Share Link and Social Share icons */}
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        gap: '10px',
        marginBottom: '10px'
    }}>
        {post.instagram && (
            <FaInstagram 
                className="social-icon instagram" 
                onClick={handleInstagramClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {post.twitter && (
            <FaTwitter 
                className="social-icon twitter" 
                onClick={handleTwitterClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {post.tiktok && (
            <FaTiktok 
                className="social-icon tiktok" 
                onClick={handleTiktokClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {post.youtube && (
            <FaYoutube 
                className="social-icon youtube" 
                onClick={handleYoutubeClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {post.facebook && (
            <FaFacebook 
                className="social-icon facebook" 
                onClick={handleFacebookClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
    </div>

    {/* Email with title styling */}
    {post.email && (
        <div style={{ 
            width: 'fit-content',
            textAlign: 'center',
            margin: '0 auto',
            marginBottom: '10px'
        }}>
            <a 
                href={`mailto:${post.email}`} 
                style={{ 
                    color: '#1a0dab',  // Changed to match title color
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: 500  // Added to match title weight
                }}
            >
                {post.email}
            </a>
        </div>    
    )}

    {/* Contact List button and View Email Sign Ups */}
    <div style={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
    }}>
        {/* Email Updates Sign Up */}
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            width: '100%',
            alignItems: 'center',
            gap: '10px'
        }}>
            <Tooltip title="Join Contact List">
            <IconButton onClick={toggleContactList} style={{ padding: '4px', order: 1, color: '#1a0dab' }}>
                <GoAlert />
            </IconButton>
            </Tooltip>
            <Typography
                variant="body2"
                onClick={toggleContactList}
                style={{
                    color: '#1a0dab',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontFamily: '"Roboto", sans-serif',
                    order: 2
                }}
            >
                Email Updates Sign Up
            </Typography>
        </div>

        {/* View Email Sign Ups - Only visible to post author */}
        {isAuthor && (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                width: '100%',
                alignItems: 'center',
                gap: '10px'
            }}>
                <CiViewList 
                    onClick={() => navigate('/view-contact-list')}
                    style={{ 
                        fontSize: '24px', 
                        cursor: 'pointer',
                        color: '#1a0dab'
                    }} 
                />
                <Typography
                    variant="body2"
                    onClick={() => navigate('/view-contact-list')}
                    style={{
                        color: '#1a0dab',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        fontFamily: '"Roboto", sans-serif'
                    }}
                >
                    View Email Sign Ups
                </Typography>
            </div>
        )}
    </div>
</div>

{/* Categories - Moved to bottom */}
<div className="categories-container" style={{ 
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
    padding: '0 16px'
}}>
    {post.categories && Array.isArray(post.categories) && post.categories.map((category) => (
        <span
            key={category}
            className="category-chip"
            onClick={(e) => {
                e.stopPropagation();
                navigateToCategoryPosts(category);
            }}
            style={{
                cursor: 'pointer',
                backgroundColor: '#f0f0f0',
                padding: '5px 12px',
                borderRadius: '16px',
                fontSize: '14px',
                color: '#333',
                border: '1px solid #ddd',
                transition: 'background-color 0.2s',
                display: 'inline-block',
                margin: '2px',
                fontFamily: '"Roboto", sans-serif',
                fontWeight: 600
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
        >
            {category}
        </span>
    ))}
</div>

{/* Feedback Section */}
<div style={{ 
    padding: '0 16px', 
    marginTop: '10px', 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center' 
}}>
    {isLoggedIn ? (
        <Feedback postId={id} />
    ) : (
        <Typography 
            variant="body1" 
            color="text.secondary" 
            style={{ 
                textAlign: 'center',
                fontSize: '1.1rem',
                padding: '10px 0'
            }}
        >
            <span 
                onClick={() => navigate('/login')} 
                style={{ color: '#1a0dab', cursor: 'pointer', textDecoration: 'underline' }}
            >
                Login
            </span> or <span 
                onClick={() => navigate('/signup')} 
                style={{ color: '#1a0dab', cursor: 'pointer', textDecoration: 'underline' }}
            >
                Sign Up
            </span> to View & Provide Feedback
        </Typography>
    )}
</div>

{/* Report Form */}
{showReportForm && (
    <ClipsReportForm
        postId={id}
        onSubmit={(reason) => {
            reportPost(id, reason);
            setShowReportForm(false);
        }}
        onClose={() => setShowReportForm(false)}
    />
)}

{/* Contact List */}
{showContactList && (
    <ContactList
        postId={id}
        onClose={closeContactList}
    />
)}

                    {/* Chat Window */}
                    {showChatWindow && (
                        <ChatWindow
                            recipientId={post.authorId}
                            recipientName={post.author}
                            onClose={() => setShowChatWindow(false)}
                        />
                    )}

                {/* Share Post Section */}
                <div
                    style={{
                        marginTop: '16px',
                        alignSelf: 'center',
                        textAlign: 'center',
                    }}
                >
                    {!showSharePost ? (
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <Tooltip title="Share this Post">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowSharePost(true);
                                    }}
                                    style={{ padding: '4px', color: '#1a0dab' }}
                                >
                                    <BsMegaphone style={{ fontSize: '24px' }} />
                                </IconButton>
                            </Tooltip>
                            <Typography
                                variant="body2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSharePost(true);
                                }}
                                style={{
                                    color: '#1a0dab',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    fontFamily: '"Roboto", sans-serif',
                                }}
                            >
                                Share this Post
                            </Typography>
                        </div>
                    ) : (
                        <div
                            style={{
                                marginTop: '10px',
                                padding: '15px 20px',
                                paddingTop: '50px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#f9f9f9',
                                position: 'relative',
                                display: 'inline-block',
                            }}
                        >
                            {/* Close Button */}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSharePost(false);
                                }}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    padding: '0',
                                    color: '#666',
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    fontWeight: 'bold',
                                }}
                                aria-label="Close Share Options"
                            >
                                ×
                            </button>

                            {/* Share Options Component */}
                            <SharePost url={`${window.location.origin}/clipsposts/${id}`} />
                        </div>
                    )}
                </div>
                
                {/* Analytics Display section */}
                {isAuthor && (
                    <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px 16px 40px 16px',
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        marginTop: '20px',
                        gap: '10px',
                        width: '100%',
                        backgroundColor: '#f8f9fa'
                    }}>
                        <Typography variant="h6" color="text.primary" style={{ 
                            marginBottom: '15px',
                            fontWeight: 600,
                            textAlign: 'center',
                            color: '#000000'
                        }}>
                            Your Post Analytics
                        </Typography>
                        <Typography variant="body2" style={{ color: '#000000' }}>
                            Post Page Views: {post?.viewCount || 0}
                        </Typography>
                        <Typography variant="body2" style={{ color: '#000000' }}>
                            URL Link Clicks: {post?.linkClickCount || 0}
                        </Typography>
                        <Typography variant="body2" style={{ color: '#000000' }}>
                            Post Impressions (Views in Feeds & Discovery Boxes): {post?.impressionCount || 0}
                        </Typography>
                        {post?.instagram && (
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                Instagram Clicks: {post?.instagramClickCount || 0}
                            </Typography>
                        )}
                        {post?.twitter && (
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                Twitter Clicks: {post?.twitterClickCount || 0}
                            </Typography>
                        )}
                        {post?.tiktok && (
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                TikTok Clicks: {post?.tiktokClickCount || 0}
                            </Typography>
                        )}
                        {post?.youtube && (
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                YouTube Clicks: {post?.youtubeClickCount || 0}
                            </Typography>
                        )}
                        {post?.facebook && (
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                Facebook Clicks: {post?.facebookClickCount || 0}
                            </Typography>
                        )}
                    </div>
                )}
                </Card>

                {/* Random Posts Section */}
                {randomPosts.length > 0 && (
                    <Fade in={true} timeout={800}>
                        <Paper
                            elevation={3}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                p: 4,
                                mt: 4,
                                width: '100%',
                                maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                                mb: 2,
                                marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                                marginRight: isSurfacePro7 ? 'auto' : '0',
                                borderRadius: '16px',
                                backgroundColor: '#fff',
                                overflow: 'hidden',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 4 }}>
                                <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
                                    Discover Posts
                                </Typography>
                                <IconButton 
                                    onClick={handleRefresh}
                                    sx={{ 
                                        color: '#007aff',
                                        backgroundColor: '#f5f5f7',
                                        '&:hover': { backgroundColor: '#e8e8ed' },
                                        transition: 'background-color 0.3s ease',
                                    }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Box>

                            <List sx={{ width: '100%', padding: 0 }}>
                                {randomPosts.map((post, index) => (
                                    <VisibilityTracker key={post.id} postId={post.id} isDiscoveryBox={true}>
                                        <Fade in={true} timeout={500 + index * 100}>
                                            <ListItem 
                                                sx={{ 
                                                    display: 'flex', 
                                                    flexDirection: 'column', 
                                                    alignItems: 'flex-start',
                                                    padding: '16px 0',
                                                    borderRadius: '8px',
                                                    '&:hover': { backgroundColor: 'transparent' },
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        onClick={() => handlePostClick(post.id)}
                                                        sx={{
                                                            color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                                                            textDecoration: 'none',
                                                            cursor: 'pointer',
                                                            fontWeight: 'bold',
                                                            '&:hover': { textDecoration: 'underline' },
                                                            fontSize: '1.1rem',
                                                            marginBottom: '4px',
                                                        }}
                                                    >
                                                        {post.title}
                                                    </Typography>
                                                    <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
                                                        {post.city && post.state ? `(${post.city}, ${post.state})` : 
                                                        post.city ? `(${post.city})` : 
                                                        post.state ? `(${post.state})` : ''}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body2" sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}>
                                                    {post.summary || (post.description.length > 100 ? `${post.description.substring(0, 100)}...` : post.description)}
                                                </Typography>
                                            </ListItem>
                                        </Fade>
                                    </VisibilityTracker>
                                ))}
                            </List>
                        </Paper>
                    </Fade>
                )}

                <CloseButton />
            </Box>
        </VisibilityTracker>
    </FeedbackProvider>
);
};

export default ClipsPostPage;