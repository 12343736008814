// VisibilityTracker.js

import React, { useEffect, useRef, useState } from 'react';
import { doc, setDoc, increment, onSnapshot } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

const VisibilityTracker = ({ 
    postId, 
    children, 
    isDiscoveryBox, 
    isPostAuthor,
    onImpressionUpdate 
}) => {
    const ref = useRef(null);
    const observerRef = useRef(null);
    const impressionTrackedRef = useRef(false);
    const [localImpressionCount, setLocalImpressionCount] = useState(0);

    useEffect(() => {
        if (!postId) return;

        const shouldTrackImpression = async () => {
            const now = new Date().getTime();
            const lastTracked = localStorage.getItem(`lastImpression_${postId}`);
            
            if (!lastTracked || (now - parseInt(lastTracked)) > 3600000) { // 1 hour in milliseconds
                localStorage.setItem(`lastImpression_${postId}`, now.toString());
                return true;
            }
            return false;
        };

        const updateLocalImpressionCount = () => {
            const count = parseInt(localStorage.getItem(`localImpressionCount_${postId}`) || '0');
            setLocalImpressionCount(count + 1);
            localStorage.setItem(`localImpressionCount_${postId}`, (count + 1).toString());
        };

        // Set up the intersection observer
        observerRef.current = new IntersectionObserver(
            async ([entry]) => {
                if (entry.isIntersecting && !impressionTrackedRef.current) {
                    impressionTrackedRef.current = true;
                    
                    if (isDiscoveryBox) {
                        // Handle discovery box impressions
                        const impressionRef = doc(db, 'discoveryBoxImpressions', postId);
                        try {
                            await setDoc(impressionRef, { count: increment(1) }, { merge: true });
                            updateLocalImpressionCount();
                        } catch (error) {
                            console.error('Error updating discovery box impression:', error);
                        }
                    } else {
                        // For Post Author, update the post document directly
                        if (isPostAuthor) {
                            const postRef = doc(db, 'clipsPosts', postId);
                            try {
                                await setDoc(postRef, { impressionCount: increment(1) }, { merge: true });
                                updateLocalImpressionCount();
                            } catch (error) {
                                console.error('Error updating post impression:', error);
                            }
                        } else {
                            // For viewers, use the simplified hourly tracking
                            const shouldTrack = await shouldTrackImpression();
                            if (shouldTrack) {
                                const impressionRef = doc(db, 'postImpressions', postId);
                                try {
                                    await setDoc(impressionRef, { count: increment(1) }, { merge: true });
                                    updateLocalImpressionCount();
                                } catch (error) {
                                    console.error('Error updating post impression:', error);
                                }
                            } else {
                                // If we shouldn't track in Firestore, still update local count
                                updateLocalImpressionCount();
                            }
                        }
                    }

                    // Only unobserve if ref.current is a valid element
                    if (ref.current instanceof Element) {
                        observerRef.current.unobserve(ref.current);
                    }
                }
            },
            {
                threshold: 0.5,
            }
        );

        // Start observing the element
        if (ref.current) {
            observerRef.current.observe(ref.current);
        }

        // Set up real-time listener for impression updates
        const unsubscribe = onSnapshot(doc(db, 'postImpressions', postId), (docSnap) => {
            if (docSnap.exists() && onImpressionUpdate) {
                const firestoreCount = docSnap.data().count || 0;
                const totalCount = firestoreCount + localImpressionCount;
                onImpressionUpdate(totalCount);
            }
        });

        return () => {
            // Clean up both the observer and the snapshot listener
            if (observerRef.current) {
                if (ref.current instanceof Element) {
                    observerRef.current.unobserve(ref.current);
                }
                observerRef.current.disconnect();
            }
            unsubscribe();
        };
    }, [postId, isDiscoveryBox, isPostAuthor, onImpressionUpdate, localImpressionCount]);

    return <div ref={ref}>{children}</div>;
};

export default VisibilityTracker;
