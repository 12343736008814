// ClipsPost.js

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardActions, CardContent, CardMedia, Typography, IconButton, Tooltip, Box } from '@mui/material';
import { BsMegaphone } from 'react-icons/bs';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaRegPaperPlane, FaInstagram, FaTwitter, FaTiktok, FaYoutube, FaFacebook } from 'react-icons/fa';
import { GoAlert } from "react-icons/go";
import { CiViewList } from "react-icons/ci";
import { useAuth } from '../contexts/AuthContext';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { timeAgo } from '../utils/timeFormatter';
import ClipsReportForm from './ClipsReportForm';
import ChatWindow from './ChatWindow';
import ContactList from './ContactList';
import SharePost from './SharePost';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, getDocs, doc } from 'firebase/firestore';
import CloseButton from './CloseButton';
import Feedback from './Feedback';
import { FeedbackProvider } from '../contexts/FeedbackContext';
import VisibilityTracker from './VisibilityTracker';
import { 
    incrementLinkClickCount, 
    incrementImpressionCount,
    incrementInstagramClickCount,
    incrementTwitterClickCount,
    incrementTiktokClickCount,
    incrementYoutubeClickCount,
    incrementFacebookClickCount 
} from '../utils/dbUtilities';

// Existing shortenUrl function
const shortenUrl = (url) => {
    const maxLength = 25;
    return url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
};

const ClipsPost = ({
    id,
    title,
    author,
    description,
    city,
    state,
    country,
    location,
    categories,
    imageUrl,
    timestamp,
    lastEdited,
    link,
    email,
    instagram,
    twitter,
    tiktok,
    youtube,
    facebook,
    isSmall,
    bio,
    profileImageUrl,
    userName
}) => {
    const navigate = useNavigate();
    const { currentUser, isLoggedIn, isAdmin } = useAuth(); // Added isAdmin
    const { reportPost } = useContext(ClipsReportsContext);
    const { deletePostById } = useContext(ClipsPostsContext);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [showReportForm, setShowReportForm] = useState(false);
    const [showContactList, setShowContactList] = useState(false);
    const [showSharePost, setShowSharePost] = useState(false);
    const [isAuthor, setIsAuthor] = useState(currentUser && currentUser.displayName === author);
    const [showChatWindow, setShowChatWindow] = useState(false);
    const [chatWindowWidth, setChatWindowWidth] = useState(300);
    const [isProfileImageExpanded, setIsProfileImageExpanded] = useState(false);
    const [post, setPost] = useState({
        isDeleted: false,
        viewCount: 0,
        linkClickCount: 0,
        impressionCount: 0,
        instagramClickCount: 0,
        twitterClickCount: 0,
        tiktokClickCount: 0,
        youtubeClickCount: 0,
        facebookClickCount: 0
    });

    // Add function to check if user can see likes count
    const canSeeLikesCount = () => {
        console.log('Checking likes count access:', {
            isAuthor: isAuthor,
            isAdmin: isAdmin,
            currentUser: currentUser
        });
        return isAuthor || isAdmin;
    };
    
    const [clickedPosts, setClickedPosts] = useState(() => {
        const saved = localStorage.getItem('clickedPosts');
        return saved ? JSON.parse(saved) : {};
    });

    const splitText = (text) => {
        if (!text) return [];
        return text.split(/(?<!\b(?:Dr|Mr|Mrs|Miss|Ms|Prof|Rev|Hon|St|Ave|Blvd|Rd|Ln|Jr|Sr|Inc|Ltd|Co|Corp|Gov|Sen|Rep|Col|Gen|Capt|Lt|Maj|Adm|Cpl|Sgt|Pvt|Pres|VP|Dir|Mgr|Asst|Supt|Treas|Sec|Chmn|Cmdr|Dep|Adm|Supt|Eng|Arch|Dept|Div|Esq)\.)(?<!\b[A-Z][a-z]*\.)(?<=\.|\?|!)\s+(?=[A-Z])/g);
    };
    
    // Existing likes effect
    useEffect(() => {
        const likesQuery = query(collection(db, 'clipsLikes'), where('postId', '==', id));
        const unsubscribe = onSnapshot(likesQuery, (snapshot) => {
            const likes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLikeCount(likes.length);
            if (currentUser) {
                setIsLiked(likes.some(like => like.userId === currentUser.uid));
                setIsAuthor(currentUser.displayName === author);
            }
        });
    
        return () => {
            unsubscribe();
        };
    }, [id, currentUser, author]);
    
    // New real-time analytics effect
    useEffect(() => {
        if (id) {
            const postRef = doc(db, 'clipsPosts', id);
            const unsubscribe = onSnapshot(postRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    setPost(prevPost => ({
                        ...prevPost,
                        viewCount: data.viewCount || 0,
                        linkClickCount: data.linkClickCount || 0,
                        impressionCount: data.impressionCount || 0,
                        instagramClickCount: data.instagramClickCount || 0,
                        twitterClickCount: data.twitterClickCount || 0,
                        tiktokClickCount: data.tiktokClickCount || 0,
                        youtubeClickCount: data.youtubeClickCount || 0,
                        facebookClickCount: data.facebookClickCount || 0
                    }));
                }
            });
    
            return () => unsubscribe();
        }
    }, [id]);
    
    useEffect(() => {
        const handleStorage = () => {
            const saved = localStorage.getItem('clickedPosts');
            if (saved) {
                setClickedPosts(JSON.parse(saved));
            }
        };
    
        window.addEventListener('storage', handleStorage);
        return () => window.removeEventListener('storage', handleStorage);
    }, []);
    
    const handleLike = async (event) => {
        event.stopPropagation();
        if (!isLoggedIn || !currentUser || !id) {
            navigate('/login');
            return;
        }
    
        if (isLiked) {
            const likeQuery = query(collection(db, 'clipsLikes'), where('postId', '==', id), where('userId', '==', currentUser.uid));
            const snapshot = await getDocs(likeQuery);
            const likeDoc = snapshot.docs[0];
            if (likeDoc) {
                await deleteDoc(likeDoc.ref);
            }
        } else {
            await addDoc(collection(db, 'clipsLikes'), {
                postId: id,
                userId: currentUser.uid,
                timestamp: new Date()
            });
        }
    };
    
    const handleUsernameClick = (e) => {
        e.stopPropagation();
        navigate(`/clipsusersposts/${author}`);
    };
    
    const navigateToPostPage = () => {
        navigate(`/clipsposts/${id}`);
    };
    
    const handleTitleClick = (e) => {
        e.stopPropagation();
        const updatedClickedPosts = { ...clickedPosts, [id]: true };
        setClickedPosts(updatedClickedPosts);
        localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
        navigateToPostPage();
    };
    
    // Updated social media click handlers
    const handleLinkClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(link.includes('http') ? link : `https://${link}`, '_blank', 'noopener,noreferrer');
    };    
    
    const handleInstagramClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(`https://instagram.com/${instagram.replace('@', '')}`, '_blank', 'noopener,noreferrer');
    };
    
    const handleTwitterClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(`https://twitter.com/${twitter.replace('@', '')}`, '_blank', 'noopener,noreferrer');
    };
    
    const handleTiktokClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(`https://tiktok.com/@${tiktok.replace('@', '')}`, '_blank', 'noopener,noreferrer');
    };
    
    const handleYoutubeClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(`https://youtube.com/@${youtube.replace('@', '')}`, '_blank', 'noopener,noreferrer');
    };
    
    const handleFacebookClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(`https://www.facebook.com/${facebook}`, '_blank', 'noopener,noreferrer');
    };    
    
    const navigateToCategoryPosts = (category) => {
        navigate(`/categoryposts/${category}`);
    };
    
    const navigateToCityPosts = (city) => {
        navigate(`/cityposts/${city}`);
    };
    
    const navigateToStatePosts = (state) => {
        navigate(`/stateposts/${state}`);
    };
    
    const navigateToCountryPosts = (country) => {
        navigate(`/countryposts/${country}`);
    };
    
    const navigateToLocationPosts = (location) => {
        navigate(`/locationposts/${location}`);
    };
    
    const toggleReportForm = (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setShowReportForm(!showReportForm);
    };
    
    const toggleContactList = (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setShowContactList(!showContactList);
    };
    
    const closeContactList = () => {
        setShowContactList(false);
    };

    const toggleSharePost = (e) => {
        e.stopPropagation();
        setShowSharePost((prev) => !prev);
    };
    
    const closeSharePost = () => {
        setShowSharePost(false);
    };
    
    const handleRemove = async (e) => {
        e.stopPropagation();
        if (window.confirm("Are you sure you want to delete this post?")) {
          await deletePostById(id);
        }
    };      
    
    const handleEdit = (e) => {
        e.stopPropagation();
        navigate(`/editclipspost/${id}`);
    };
    
    const openChatWindow = (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setShowChatWindow(true);
    };
    
    const openLinkInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    
    const handleProfileImageClick = () => {
        setIsProfileImageExpanded(!isProfileImageExpanded);
    };

    if (!post || post.isDeleted) {
        return null;
    }
    
    return (
        <FeedbackProvider>
        <VisibilityTracker 
            postId={id} 
            isDiscoveryBox={false} 
            isPostAuthor={currentUser?.uid === post.authorId}
            onImpressionUpdate={(count) => {
                setPost(prev => ({...prev, viewerImpressions: count}));
            }}
        >
            <Card className={`post ${isSmall ? 'small-post' : ''} wider-post shorter-post`} sx={{ marginBottom: 2, position: 'relative' }}>
                    {imageUrl && (
                        <CardMedia
                            component="img"
                            src={imageUrl}
                            alt={title}
                        />
                    )}
                    <CardContent
    sx={{
        width: '100%',
        alignItems: 'flex-start',
        position: 'relative',
        boxSizing: 'border-box',
        paddingTop: '40px', // Keep the original paddingTop
    }}
>
    <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            boxSizing: 'border-box',
        }}
    >
        {/* Wrapper to move title and location text up */}
        <div style={{ marginTop: '-10px' }}> {/* Adjust this as needed */}
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="clips-post-title"
                style={{
                    textAlign: 'left',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '5px', // Maintains spacing with location
                    width: '100%',
                }}
                onClick={handleTitleClick}
            >
                <span
                    className="title-link"
                    style={{
                        flex: '1 1 auto',
                        wordBreak: 'break-word',
                        color: clickedPosts[id] ? '#551A8B' : '#1a0dab',
                    }}
                >
                    {title}
                </span>
            </Typography>

            <Typography
                variant="body2"
                color="text.secondary"
                className="clips-post-location"
                style={{ 
                    textAlign: 'left', 
                    width: '100%', 
                    cursor: 'pointer',
                    marginBottom: '15px', // Increased margin for spacing from description
                }}
            >
                <span onClick={(e) => { e.stopPropagation(); navigateToCityPosts(city); }} className="location-link">
                    {city}
                </span>
                {state && (
                    <>
                        ,{' '}
                        <span onClick={(e) => { e.stopPropagation(); navigateToStatePosts(state); }} className="location-link">
                            {state}
                        </span>
                    </>
                )}
                {country && (
                    <>
                        ,{' '}
                        <span onClick={(e) => { e.stopPropagation(); navigateToCountryPosts(country); }} className="location-link">
                            {country}
                        </span>
                    </>
                )}
                {' '}
                (<span onClick={(e) => { e.stopPropagation(); navigateToLocationPosts(location); }} className="location-link">
                    {location}
                </span>)
            </Typography>
        </div>

        {/* Description text remains in the same position */}
        <Typography 
    variant="body1" 
    color="text.primary" 
    style={{ 
        textAlign: 'left', 
        width: '100%',
        margin: '10px 0 20px 0',  // Increased marginBottom for more spacing before timestamp
        fontSize: '15px',
        lineHeight: 1.4,
        color: '#000000',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
    }}
>
    {splitText(description).length > 1 ? (
        <ul style={{ paddingLeft: '20px', marginTop: '5px' }}>
            {splitText(description).map((sentence, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>{sentence.trim()}</li>
            ))}
        </ul>
    ) : (
        description
    )}
</Typography>

<Typography
    variant="body2"
    color="text.secondary"
    className="clips-post-author"
    style={{ 
        textAlign: 'left', 
        width: '100%', 
        cursor: 'pointer',
        fontFamily: '"Roboto", sans-serif',
        fontSize: '13px' // Changed font size for timestamp
    }}
    onClick={handleUsernameClick}
>
    Posted {timeAgo(timestamp?.toDate ? timestamp.toDate() : timestamp)} by <span className="username-link" style={{ fontWeight: 'bold', fontFamily: '"Roboto", sans-serif' }}>{author}</span>
</Typography>

{lastEdited && (
    <Typography
        variant="body2"
        color="text.secondary"
        className="last-edited"
        style={{
            textAlign: 'left',
            width: '100%',
            cursor: 'pointer',
            fontFamily: '"Roboto", sans-serif',
            fontSize: '13px', // Match the font size of the posted timestamp
            marginTop: '5px', // Add some spacing between the timestamps
        }}
    >
        Last Edited {timeAgo(lastEdited?.toDate? lastEdited.toDate() : lastEdited)}
    </Typography>
)}

{/* Post Impressions Count for Post Viewers */}
<Box 
    sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        width: '100%',
        mt: 2,
        mb: 2
    }}
>
    <Typography 
        variant="body1" 
        sx={{ 
            color: '#000000', 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '15px',
            lineHeight: 1.4,
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 700, // Changed from 400 to 700 (bold)
        }}
    >
        <span style={{ 
            backgroundColor: '#fff', 
            color: '#000', 
            fontFamily: '"Roboto", sans-serif', 
            fontSize: '15px', 
            padding: '3px 8px', 
            border: '2px solid #000', 
            borderRadius: '6px', 
            marginRight: '8px' 
        }}>
            {post?.impressionCount?.toLocaleString() || 0}
        </span>
        Total Views
    </Typography>
</Box>

{/* URL Link - Centered with title styling */}
{link && (
    <div style={{ 
        width: '100%',
        textAlign: 'center',
        marginTop: '15px',
        marginBottom: '10px'
    }}>
        <a 
            href={link.includes('http') ? link : `https://${link}`}
            onClick={handleLinkClick}
            style={{ 
                color: '#1a0dab',
                textDecoration: 'none',
                wordBreak: 'break-all',
                display: 'inline-block',
                fontSize: '16px',
                fontWeight: 500
            }}
        >
            {shortenUrl(link)}
        </a>
    </div>
)}

{/* Profile Image and Info */}
{(userName || bio || profileImageUrl) && (
    <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0px',
            marginTop: '15px',
            boxSizing: 'border-box',
        }}
    >
                        {/* Profile Image and Name Box */}
                        <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        backgroundColor: 'white',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    {profileImageUrl && (
                        <div
                            style={{
                                cursor: 'pointer',
                                marginBottom: '3px',
                                width: '100%',
                                height: isProfileImageExpanded ? 'auto' : '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'white',
                            }}
                            onClick={handleProfileImageClick}
                        >
                            <div
                                style={{
                                    width: isProfileImageExpanded ? '100%' : '200px',
                                    height: isProfileImageExpanded ? 'auto' : '200px',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease-in-out',
                                }}
                            >
                                <img
                                    src={profileImageUrl}
                                    alt="Profile"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        borderRadius: '50%',
                                        transition: 'all 0.3s ease-in-out',
                                    }}
                                />
                            </div>
                            {isProfileImageExpanded && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        zIndex: 10,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsProfileImageExpanded(false);
                                    }}
                                >
                                    <span style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>×</span>
                                </div>
                            )}
                        </div>
                    )}
                    {userName && !isProfileImageExpanded && (
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginTop: profileImageUrl ? '0' : '3px',
                                marginBottom: '0',
                                width: '100%',
                                lineHeight: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '30px',
                            }}
                        >
                            {userName}
                        </Typography>
                    )}
                </div>

                {/* Brief Bio Box */}
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        backgroundColor: 'white',
                        padding: bio ? '5px 5px 0 5px' : '0',
                    }}
                >
                    {bio && (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{
                                fontSize: '15px',
                                lineHeight: 1.4,
                                color: '#000000',
                                fontFamily: '"Roboto", sans-serif',
                                fontWeight: 400,
                                marginBottom: '0',
                            }}
                        >
                            {splitText(bio).length > 1 ? (
                                <ul style={{ paddingLeft: '20px', margin: '0' }}>
                                    {splitText(bio).map((sentence, index) => (
                                        <li key={index} style={{ marginBottom: '3px' }}>{sentence.trim()}</li>
                                    ))}
                                </ul>
                            ) : (
                                bio
                            )}
                        </Typography>
                    )}
                </div>
            </div>
        )}

            </div>
</CardContent>

<CardActions disableSpacing style={{ justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={isLiked ? 'Unlike' : 'Like'}>
            <IconButton onClick={handleLike} sx={{ color: isLiked ? 'red' : 'inherit' }}>
                {isLiked ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
        </Tooltip>
        {canSeeLikesCount() && (
            <span className="votes-count">{likeCount}</span>
        )}
    </div>
    <div style={{ display: 'flex', gap: '10px' }}>
        {isAuthor ? (
            <>
                <Tooltip title="Edit">
                    <IconButton onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton onClick={handleRemove}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
        ) : (
            <>
                <Tooltip title="Report">
                    <IconButton onClick={toggleReportForm}>
                        <OutlinedFlagIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Send Message">
                    <IconButton onClick={openChatWindow} style={{ color: 'inherit' }}>
                        <FaRegPaperPlane size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </IconButton>
                </Tooltip>
            </>
        )}
    </div>
</CardActions>

{/* Add the Feedback component here */}
<div style={{ 
    padding: '0 16px', 
    marginTop: '10px', 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center' 
}}>
    {isLoggedIn ? (
        <Feedback postId={id} />
    ) : (
        <Typography 
            variant="body1" 
            color="text.secondary" 
            style={{ 
                textAlign: 'center',
                fontSize: '1.1rem',
                padding: '10px 0'
            }}
        >
            <span 
                onClick={() => navigate('/login')} 
                style={{ color: '#1a0dab', cursor: 'pointer', textDecoration: 'underline' }}
            >
                Login
            </span> or <span 
                onClick={() => navigate('/signup')} 
                style={{ color: '#1a0dab', cursor: 'pointer', textDecoration: 'underline' }}
            >
                Sign Up
            </span> to View & Provide Feedback
        </Typography>
    )}
</div>

{/* Container for Email, Social Share icons, Share Link, and Contact List button */}
<div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%'
}}>

{/* Conditional Heading for Social Handles */}
{(instagram || twitter || tiktok || youtube || facebook) && (
    <Typography 
        variant="h6" 
        style={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '10px',
            color: '#000'
        }}
    >
        Follow the Growth
    </Typography>
)}

    {/* Share Link and Social Share icons */}
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        gap: '10px',
        marginBottom: '10px'
    }}>
        {instagram && (
            <FaInstagram 
                className="social-icon instagram" 
                onClick={handleInstagramClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {twitter && (
            <FaTwitter 
                className="social-icon twitter" 
                onClick={handleTwitterClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {tiktok && (
            <FaTiktok 
                className="social-icon tiktok" 
                onClick={handleTiktokClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {youtube && (
            <FaYoutube 
                className="social-icon youtube" 
                onClick={handleYoutubeClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
        {facebook && (
            <FaFacebook 
                className="social-icon facebook" 
                onClick={handleFacebookClick} 
                style={{ fontSize: '28px', cursor: 'pointer' }} 
            />
        )}
    </div>

    {/* Email */}
    {email && (
        <div style={{ 
            width: 'fit-content',
            textAlign: 'center',
            margin: '0 auto',
            marginBottom: '10px'
        }}>
            <a 
                href={`mailto:${email}`} 
                style={{ 
                    color: '#1a0dab',  // Changed to match title color
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: 500  // Added to match title weight
                }}
            >
                {email}
            </a>
        </div>    
    )}

    {/* Contact List button */}
<div style={{ 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
}}>
    {/* Email Updates Sign Up */}
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%',
        alignItems: 'center',
        gap: '10px'
    }}>
        <Tooltip title="Join Contact List">
            <IconButton onClick={toggleContactList} style={{ padding: '4px', order: 1, color: '#1a0dab' }}>
                <GoAlert />
            </IconButton>
        </Tooltip>
        <Typography
            variant="body2"
            onClick={toggleContactList}
            style={{
                color: '#1a0dab',
                cursor: 'pointer',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontFamily: '"Roboto", sans-serif',
                order: 2
            }}
        >
            Email Updates Sign Up
        </Typography>
    </div>

    {/* View Email Sign Ups - Only visible to post author */}
    {isAuthor && (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            width: '100%',
            alignItems: 'center',
            gap: '10px'
        }}>
            <Tooltip title="View Email Sign Ups">
                <IconButton onClick={() => navigate('/view-contact-list')} style={{ padding: '4px', order: 1, color: '#1a0dab' }}>
                    <CiViewList style={{ fontSize: '24px' }} />
                </IconButton>
            </Tooltip>
            <Typography
                variant="body2"
                onClick={() => navigate('/view-contact-list')}
                style={{
                    color: '#1a0dab',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontFamily: '"Roboto", sans-serif',
                    order: 2
                }}
            >
                View Email Sign Ups
            </Typography>
        </div>
    )}

{/* Categories - Moved to bottom */}
<div className="categories-container" style={{ 
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
    padding: '0 16px'
}}>
    {categories && Array.isArray(categories) && categories.map((category) => (
        <span
            key={category}
            className="category-chip"
            onClick={(e) => {
                e.stopPropagation();
                navigateToCategoryPosts(category);
            }}
            style={{
                cursor: 'pointer',
                backgroundColor: '#f0f0f0',
                padding: '5px 12px',
                borderRadius: '16px',
                fontSize: '14px',
                color: '#333',
                border: '1px solid #ddd',
                transition: 'background-color 0.2s',
                display: 'inline-block',
                margin: '2px',
                fontFamily: '"Roboto", sans-serif', // Added to match other text
                fontWeight: 600 // Added to match other text
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
        >
            {category}
        </span>
    ))}
</div>
</div>
                    </div>

                    {showReportForm && (
                        <div onClick={(e) => e.stopPropagation()}>
                            <ClipsReportForm postId={id} submitReport={reportPost} closeModal={() => setShowReportForm(false)} />
                        </div>
                    )}

                    {showChatWindow && (
                        <ChatWindow
                            initialRecipient={author}
                            onClose={() => setShowChatWindow(false)}
                            width={chatWindowWidth}
                            setWidth={setChatWindowWidth}
                        />
                    )}
                    {showContactList && (
                        <ContactList
                            postId={id}
                            closeModal={closeContactList}
                        />
                    )}

                {/* Share Post Section */}
                <div style={{ 
                    marginTop: '10px',  // Reduced from 16px
                    textAlign: 'center',
                    marginBottom: '20px'  // Reduced from 40px
                }}>
                    {!showSharePost ? (
                        <div style={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                            paddingBottom: '0px'  // Removed padding
                        }}>
                            <Tooltip title="Share this Post">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowSharePost(true);
                                    }}
                                    style={{ padding: '4px', color: '#1a0dab' }}
                                >
                                    <BsMegaphone style={{ fontSize: '24px' }} />
                                </IconButton>
                            </Tooltip>
                            <Typography
                                variant="body2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSharePost(true);
                                }}
                                style={{
                                    color: '#1a0dab',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    fontFamily: '"Roboto", sans-serif',
                                }}
                            >
                                Share this Post
                            </Typography>
                        </div>
                    ) : (
                        <div
                            style={{
                                marginTop: '10px',
                                padding: '15px 20px',
                                paddingTop: '50px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#f9f9f9',
                                position: 'relative',
                            }}
                        >
                            {/* Close Button */}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSharePost(false);
                                }}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    padding: '0',
                                    color: '#666',
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    fontWeight: 'bold',
                                }}
                                aria-label="Close Share Options"
                            >
                                ×
                            </button>

                            {/* Share Options Component */}
                            <SharePost url={`${window.location.origin}/clipsposts/${id}`} />
                        </div>
                    )}
                </div>

                    {/* Analytics Display section */}
                    {isAuthor && (
                        <div style={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '20px 16px 60px 16px',
                            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                            marginTop: '20px',
                            gap: '10px',
                            width: '100%',
                            backgroundColor: '#f8f9fa',
                            marginBottom: '40px'  // Add this line for additional bottom spacing
                        }}>
                            <Typography variant="h6" color="text.primary" style={{ 
                                marginBottom: '15px',
                                fontWeight: 600,
                                textAlign: 'center',
                                color: '#000000'
                            }}>
                                Your Post Analytics
                            </Typography>
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                Post Page Views: {post?.viewCount || 0}
                            </Typography>
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                URL Link Clicks: {post?.linkClickCount || 0}
                            </Typography>
                            <Typography variant="body2" style={{ color: '#000000' }}>
                                Post Impressions (Views in Feeds & Discovery Boxes): {post?.impressionCount || 0}
                            </Typography>
                            {post?.instagram && (
                                <Typography variant="body2" style={{ color: '#000000' }}>
                                    Instagram Clicks: {post?.instagramClickCount || 0}
                                </Typography>
                            )}
                            {post?.twitter && (
                                <Typography variant="body2" style={{ color: '#000000' }}>
                                    Twitter Clicks: {post?.twitterClickCount || 0}
                                </Typography>
                            )}
                            {post?.tiktok && (
                                <Typography variant="body2" style={{ color: '#000000' }}>
                                    TikTok Clicks: {post?.tiktokClickCount || 0}
                                </Typography>
                            )}
                            {post?.youtube && (
                                <Typography variant="body2" style={{ color: '#000000' }}>
                                    YouTube Clicks: {post?.youtubeClickCount || 0}
                                </Typography>
                            )}
                            {post?.facebook && (
                                <Typography variant="body2" style={{ color: '#000000' }}>
                                    Facebook Clicks: {post?.facebookClickCount || 0}
                                </Typography>
                            )}
                        </div>
                    )}
                </Card>
            </VisibilityTracker>
        </FeedbackProvider>
    );
};

export default ClipsPost;