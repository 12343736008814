// TitleManager.js

import { useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';

const routeTitles = {
  '/': 'HyroClipse',
  '/about': 'About',
  '/bio': 'Discover People', // Updated title for Bio.js
  '/categoryposts': 'Category Posts',
  '/chat': 'Chat',
  '/chatwindow': 'Chat Window',
  '/cityposts': 'City Posts',
  '/clips-admin-dashboard': 'Admin Dashboard',
  '/clipscontactform': 'Contact Form',
  '/clipshomepage': 'Home',
  '/clipslikes': 'Liked Posts',
  '/clipsmanageposts': 'Manage Posts',
  '/clipsmyposts': 'My Posts',
  '/clipspostform': 'New Post',
  '/clipsposts/:id': 'Loading Post...', // Default title for loading post
  '/clipsreportform': 'Report Post',
  '/clipssearchresults': 'Search Results',
  '/clipsusersposts/:username': 'User Posts', // Default title for user posts
  '/clipsviewreports': 'View Reports',
  '/contactlist': 'Contact List',
  '/countryposts': 'Country Posts',
  '/explore': 'Explore',
  '/feedback': 'Feedback',
  '/forgot-password': 'Forgot Password',
  '/growthhacks': 'Growth Hacks',
  '/homepage': 'Home',
  '/info': 'Info',
  '/likespage': 'Liked Items',
  '/locationposts': 'Location Posts',
  '/login': 'Login',
  '/manageposts': 'Manage Posts',
  '/messagespage': 'Messages',
  '/mypostspage': 'My Posts',
  '/newposts': 'New Posts',
  '/profilepage': 'Profile',
  '/randompost': 'Random Post',
  '/signup': 'Sign Up',
  '/stateposts': 'State Posts',
  '/suggestiongenerator': 'Suggestion Generator',
  '/summary': 'Summary',
  '/trending': 'Trending Posts',
  '/usersposts': 'User Posts',
  '/view-contact-list': 'View Contact List',
  '/viewreports': 'View Reports',
};

function TitleManager() {
  const location = useLocation();
  const { posts } = useContext(ClipsPostsContext);
  const { username, category, id } = useParams();

  useEffect(() => {
    const path = location.pathname;

    // Handle ClipsUsersPosts pages
    if (path.startsWith('/clipsusersposts/')) {
      document.title = username ? `${username}'s posts` : 'User posts';
      return;
    }

    // Handle CategoryPosts pages
    if (path.startsWith('/categoryposts/')) {
      document.title = category || 'Category Posts';
      return;
    }

    // Handle ClipsSearchResultsPage dynamically
    if (path.startsWith('/clipssearchresults')) {
      const query = new URLSearchParams(location.search).get('query');
      document.title = query ? `Search results for "${query}"` : 'Search results';
      return;
    }

    // Handle MessagesPage dynamically
    if (path === '/messages') {
      document.title = 'Messages';
      return;
    }

    // Handle ClipsEditPostForm dynamically
    if (path.startsWith('/editclipspost/')) {
      document.title = 'Edit Post';
      return;
    }

    // Handle individual post pages
    if (path.startsWith('/clipsposts/')) {
      const postId = path.split('/')[2];
      const post = posts.find((p) => p.id === postId);

      if (post && post.title) {
        document.title = post.title;
      } else {
        document.title = 'HyroClipse Post';
      }
      return;
    }

    // Handle all other routes
    const routeTitle = routeTitles[path] || 'Page Not Found';
    document.title = routeTitle;
  }, [location, posts, username, category, id]);

  return null;
}

export default TitleManager;


