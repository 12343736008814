// CategoryPosts.js

import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import ClipsPost from './ClipsPost';
import { Typography } from '@mui/material';
import './HomePage.css'; // Ensure this is imported for consistent spacing and styling
import './Post.css';

const CategoryPosts = () => {
    const { category } = useParams();
    const { posts } = useContext(ClipsPostsContext);
    const [categoryPosts, setCategoryPosts] = useState([]);
    const location = useLocation();

    useEffect(() => {
        // Set document title directly (as backup in case TitleManager fails)
        document.title = category;

        if (category && posts.length > 0) {
            const filteredPosts = posts.filter(post => post.categories && post.categories.includes(category));
            setCategoryPosts(filteredPosts);
        }

        // Scroll to top whenever category or location changes
        window.scrollTo(0, 0);
    }, [category, posts, location.pathname]);

    return (
        <div className="posts">
            <h2 
                className="category-posts-heading" 
                style={{ textAlign: 'center', marginBottom: '30px' }}
            >
                {category} Posts
            </h2>
            {categoryPosts.length > 0 ? (
                categoryPosts.map(post => (
                    <div key={post.id} className="post-container">
                        <ClipsPost 
                            id={post.id}
                            title={post.title}
                            author={post.author}
                            description={post.description}
                            location={post.location}
                            imageUrl={post.imageUrl}
                            timestamp={post.timestamp}
                            city={post.city}
                            state={post.state}
                            country={post.country}
                            categories={post.categories}
                            link={post.link}
                            instagram={post.instagram}
                            twitter={post.twitter}
                            tiktok={post.tiktok}
                            youtube={post.youtube}
                        />
                    </div>
                ))
            ) : (
                <Typography variant="body1" color="text.secondary" style={{ textAlign: 'center' }}>
                    No posts found in this category.
                </Typography>
            )}
        </div>
    );
};

export default CategoryPosts;
