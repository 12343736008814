// ViewContactList.js

import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ContactListContext } from '../contexts/ContactListContext';
import { getPostsByUserId } from '../utils/dbUtilities';
import { Navigate } from 'react-router-dom';
import { timeAgo } from '../utils/timeFormatter';
import { CircularProgress, Box } from '@mui/material';
import './ViewContactList.css';

const ViewContactList = () => {
  const [posts, setPosts] = useState([]);
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const { currentUser } = useAuth();
  const { contactList } = React.useContext(ContactListContext);

  useEffect(() => {
    const fetchPosts = async () => {
      if (!currentUser) return;
      try {
        setIsLoadingPosts(true);
        const userPosts = await getPostsByUserId(currentUser.uid, 'clipsPosts');
        const sortedUserPosts = userPosts
          .map(post => ({
            ...post,
            timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
          }))
          .sort((a, b) => b.timestamp - a.timestamp);
        
        setPosts(sortedUserPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setIsLoadingPosts(false);
      }
    };

    fetchPosts();
  }, [currentUser]);

  // Memoize posts to avoid unnecessary re-renders
  const memoizedPosts = useMemo(() => posts, [posts]);

  // Pre-sort contact lists once and memoize it
  const memoizedContactList = useMemo(() => {
    const sortedContacts = {};
    for (const postId in contactList) {
      if (Array.isArray(contactList[postId])) {
        sortedContacts[postId] = [...contactList[postId]].sort((a, b) => b.timestamp - a.timestamp);
      } else {
        sortedContacts[postId] = contactList[postId];
      }
    }
    return sortedContacts;
  }, [contactList]);

  // If not authenticated, redirect
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Show a loader until posts and contact list are ready
  // Assuming contactList is loaded in the context at the same time as posts or sooner
  const isLoading = isLoadingPosts || contactList === null;
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (memoizedPosts.length === 0) {
    return <p>You don't have any posts yet.</p>;
  }

  return (
    <div className="view-contact-list-container">
      <div className="header-box">
        <h1>Email Sign Up Lists</h1>
        <h3>Users who Signed Up for Email Updates About Your Post(s)</h3>
      </div>
      {memoizedPosts.map(post => (
        <div key={post.id} className="post-contact-list">
          <h2>{post.title || 'Untitled Post'}</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {memoizedContactList[post.id] && memoizedContactList[post.id].length > 0 ? (
                memoizedContactList[post.id].map((contact, index) => (
                  <tr key={index}>
                    <td>{contact.name}</td>
                    <td>{contact.email}</td>
                    <td>{timeAgo(contact.timestamp)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No Email List Sign Ups</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default React.memo(ViewContactList);





